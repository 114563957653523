import React, { Component } from "react";
import styled from "styled-components";
import Layout from "../layout/Layout";
import { housingBuildings } from '../data/housingBuildings';

const InvestitonsWrapper = styled.div`
  width: 100vw;
  font-size: 14px;

  ${({ theme }) => theme.media.investitionsDesktop} {
    display: flex;
    height: 100%;
    
  }
`;

const InvestitonsContent = styled.div`
    padding-top: 8rem;
    position: relative;
    background: #fafafa;
    padding-bottom: 4rem;

    ${({ theme }) => theme.media.investitionsDesktop} {
      display: block;
      width: 25%;
      min-height: 100%;
    }
`;

const DetailsBasicContainer = styled.div`
  display: block;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1.5rem 1.5rem;

  ${({ theme }) => theme.media.investitionsDesktop} {
    padding: 0 4rem 4rem;
  }
`;

const DetailsBasicHeader = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: 0;
`;
const DetailsBasicContent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

const InvestitonsContentDetails = styled.div`
  position: relative;
  background: #fff;
  width: 100%;

  ${({ theme }) => theme.media.investitionsDesktop} {
    display: flex;
    width: 75%;
    padding-top: 11.3rem;
    min-height: 100vh;
  }
`;
const DetailsContainer = styled.div`
  display: block;
  width: 100%;
  padding: 4rem 1.5rem;

  ${({ theme }) => theme.media.investitionsDesktop} {
    padding: 0 10rem;
  }
`;

const DetailsHeader = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: flex;
  
  margin-bottom: 0.7rem;
`;

const DetailsHeaderInfo = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: flex;
  
  margin-bottom: 0.7rem;
`;

const Link = styled.a`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
  line-height: 1.5rem;
  font-size: ${({ theme }) => theme.size.lead};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.red};

  ${({ theme }) => theme.media.investitionsDesktop} {
    margin: 0.5rem 2rem;
  }
`;

const LinkContainer = styled.div`
  height: fit-content;
  margin-left: 1.5rem;

  ${({ theme }) => theme.media.investitionsDesktop} {
    margin-left: 2rem;
  }
`;

const RealizationsContentBasic = styled.div`
  height: fit-content;
  margin-left: 3px;
  border-left: 3px solid ${({ theme }) => theme.colors.red};
`;

const List = styled.ul`
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  padding: 0.5rem 2rem;

  li {
    padding: 10px 0;
    font-size: calc(100% + 2px);
  }
`;

const Details = props => (
  <>
    <InvestitonsContentDetails>
      <DetailsContainer>
        <DetailsHeader>
          <DetailsHeaderInfo>Nasze realizacje</DetailsHeaderInfo>
        </DetailsHeader>
        <RealizationsContentBasic>
        <List>
          <li>Zespół budynków mieszkalnych w Dąbrowie Górniczej</li>
          <li>Szereg budynków jednorodzinnych na terenie powiatu mikołowskiego, Katowic oraz Tychów</li>
        </List>
        </RealizationsContentBasic>
      </DetailsContainer>
    </InvestitonsContentDetails>
  </>
)

class Investitions extends Component {
  state = { activeItem: 0 };

  handleClick = (index) => { this.setState({ activeItem: index }) };

  render() {
    const { activeItem } = this.state;
    return (
      <Layout>
        <InvestitonsWrapper>
          <InvestitonsContent>
            <DetailsBasicContainer>
              <DetailsBasicHeader>
                Budownictwo mieszkaniowe
              </DetailsBasicHeader>
              <DetailsBasicContent>
                Realizujemy budynki mieszkaniowe jednorodzinne, wielorodzinne oraz zespoły obiektów - bliźniaki lub w zabudowie szeregowej. Dzięki współpracy z grupą architektów oraz konstruktorów możemy zaprojektować lub zaadaptować projekt budowlany według potrzeb klienta.
              </DetailsBasicContent>
            </DetailsBasicContainer>
            <LinkContainer>
              <Link href="/budynek-na-sprzedaz">Zobacz budynki na sprzedaż</Link>
            </LinkContainer>
          </InvestitonsContent>
          <Details {...housingBuildings[activeItem]} />
        </InvestitonsWrapper>
      </Layout>
    );
  }
}

export default Investitions;