export const housingBuildings = [
  {
    id: 1,
    name: "Osiedle Jaśminowe",
    thumb: "/images/photos/budownictwo1.jpg",
    images: [
      "/images/photos/budownictwo1.jpg",
      "/images/photos/budownictwo2.jpg",
      "/images/photos/budownictwo3.jpg",
      "/images/photos/budownictwo2.jpg"
    ],
    description: [
      'Oferujemy na sprzedaż 5 segmentów w zespole budynków jednorodzinnych przy ulicy Jaśminowej w Mikołowie. Kameralne osiedle składa się z budynków o dwóch oraz trzech segmentach z niezależnymi podjazdami oraz ogródkami. Inwestycja jest bardzo dobrze połączona z dostępem do aglomeracji oraz jednocześnie znajduje się w zielonej części  Mikołowa. Nowoczesna architektura i przemyślany układ funkcjonalno-użytkowy pozwoli poczuć się bardzo komfortowo w oferowanych budynkach.',
    ],
  },
];